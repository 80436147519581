<template>
  <div>
    <van-tabs color="rgba(65, 181, 132, 1)" v-model="active" @click="onClick">
      <van-tab title="奖助项目">
        <project ref="project"></project>
      </van-tab>
      <van-tab title="我的项目">
        <my-apply ref="myapply"></my-apply>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import myApply from "@/view/modules/student/award/MyApply";
import project from "@/view/modules/student/award/project";
export default {
  name: "JzIndex",
  components: { myApply, project },
  data() {
    return {
      active: 0,
    };
  },
  created() {},
  methods: {
    onClick(value) {
      if (value === 0) {
        this.$refs.project.toQuery();
      }
    },
  },
};
</script>
<style lang='scss' scoped>
@import "~@/assets/styles/student/jzapply.scss";
</style>
