<template>
  <div id="MyApply">
    <!-- 查询 -->
    <van-dropdown-menu active-color="#23AB72">
      <van-dropdown-item v-model="query.year" :options="options" @change="changeYear" />
    </van-dropdown-menu>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span style="color:rgba(35, 171, 114, 1)">{{row.year+row.teremName}}</span><br />
            <div class="custom-title hidden-text ">{{row.schshiName}}</div>
          </template>
          <template #label>
            <div class="custom-centent  hidden-text95">
              <svg-icon icon-class="date" size="15" /> 时间: {{row.createTime}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <svg-icon icon-class="unit" size="15" /> 等级: {{row.gradeName }}<span v-if="row.pbv"> （<span style="color:#ff976a;font-weight: 500;">¥{{row.pbv}}</span>） </span>
            </div>
          </template>
          <template #right-icon>
            <span v-if="row.spzt ==='0'" class="audit info-color b-color-info">待提交</span>
            <span v-else-if="statustag(row.spzt,'success')" class="audit success-color b-color-success">审核通过</span>
            <span v-else-if="statustag(row.spzt,'info')" class="audit info-color b-color-info">待审核</span>
            <span v-else-if="statustag(row.spzt,'error')" class="audit error-color b-color-error">审核不通过</span>
            <span v-else-if="statustag(row.spzt,'warning')" class="audit warning-color b-color-warning">审核驳回</span>
            <span class="table-detail-btn detail-pos" @click="toDetail(row)" style="color:#B2B2B2"> 详情
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <span v-if="statustag(row.spzt,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
            <span v-else-if="statustag(row.spzt,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
            <span v-else-if="statustag(row.spzt,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
            <span v-else-if="statustag(row.spzt,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
          </template>
          <template #right-icon>
            <van-button class="table-del-btn" :disabled="stuDisableBtn(row.spzt,'del')" @click="toDel(row)" :loading="row.delLoading" plain round size="small" type="warning">删除</van-button>
            <van-button class="table-edit-btn" :disabled="stuDisableBtn(row.spzt,'edit')" @click="toEdit(row)" plain round size="small" type="primary">编辑</van-button>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>

    <van-popup v-model="showYearPicker" position="bottom" round :style="{ height: '40%' }">
      <van-picker show-toolbar :columns="dict.xj_nj" :default-index="defaultIndex" value-key="label" @confirm="onConfirm" @cancel="showYearPicker = false" />
    </van-popup>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import excellent from "@/api/modules/award/excellentStuJxj";
import inspiration from "@/api/modules/award/inspirationJxj";
import nation from "@/api/modules/award/nationJxj";
import custom from "@/api/modules/award/custom";
import tks from "@/api/modules/award/tkszxj";
import gjzxj from "@/api/modules/award/gjzxj";
import { mapGetters } from "vuex";
import { webService } from "@/config/settings";
import { getAwardById } from "@/api/modules/award/project";

export default {
  name: "MyApply",
  mixins: [initData],
  dicts: ["xj_shzt", "xj_nj"],
  data() {
    return {
      showYearPicker: false,
      defaultIndex: 1,
      searchTetx: "",
      webService,
      options: [],
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {},
  watch: {
    "dict.xj_nj": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0) {
          this.options = this.convertData(newval);
        }
      },
    },
  },
  methods: {
    beforeInit() {
      this.url = "api/jz/stujxj";
      this.sort = ["spzt,asc", "id,desc"];
      return true;
    },
    toDetail(sq) {
      getAwardById(sq.schshiId).then((data) => {
        if (data.isCustom === "true" || data.isCustom) {
          let param =
            "id=" +
            data.id +
            "&title=" +
            data.schshiName +
            "&stu=yes&meta=奖助申请&key=" +
            sq.sqid +
            "&schshiNo=" +
            data.schshiNo +
            "&schshiName=" +
            data.schshiName +
            "&apkToken=" +
            this.token;
          window.location.href = webService + "/#/app/custom/view?" + param;
        } else {
          this.$router.push({
            path: "/xs/a05/" + sq.schshiNo + "/detail",
            query: { id: sq.sqid, schshiName: sq.schshiName },
          });
        }
      });
    },
    // 编辑
    toEdit(sq) {
      getAwardById(sq.schshiId).then((data) => {
        if (data.isCustom === "true" || data.isCustom) {
          let param =
            "id=" +
            data.id +
            "&title=" +
            data.schshiName +
            "&stu=yes&meta=奖助申请&key=" +
            sq.sqid +
            "&schshiNo=" +
            data.schshiNo +
            "&schshiName=" +
            data.schshiName +
            "&apkToken=" +
            this.token;
          window.location.href = webService + "/#/app/custom/form?" + param;
        } else {
          this.$router.push({
            path: "/xs/a05/" + sq.schshiNo + "/form",
            query: { id: sq.sqid },
          });
        }
      });
    },
    // 删除操作
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          getAwardById(data.schshiId).then((res) => {
            if (res.isCustom === "true" || res.isCustom) {
              this.delCustom(data);
            } else {
              switch (parseInt(data.schshiNo)) {
                case 10001:
                  this.delInspiration(data);
                  break;
                case 10002:
                  this.delExcellent(data);
                  break;
                case 10003:
                  this.delNation(data);
                  break;
                case 20001:
                  this.delNationZxj(data);
                  break;
                case 20002:
                  this.delTks(data);
                  break;
                default:
                  console.log(code);
              }
            }
          });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
    // 优秀学生奖学金
    delExcellent(data) {
      this.$set(data, "delLoading", true);
      excellent
        .del([data.sqid])
        .then((res) => {
          this.$notify({
            type: "success",
            message: "操作成功",
            duration: 2000,
          });
          this.init();
          this.$set(data, "delLoading", false);
        })
        .catch((err) => {
          this.$set(data, "delLoading", false);
        });
    },
    // 国家励志奖学金
    delInspiration(data) {
      this.$set(data, "delLoading", true);
      inspiration
        .del([data.sqid])
        .then((res) => {
          this.$notify({
            type: "success",
            message: "操作成功",
            duration: 2000,
          });
          this.init();
          this.$set(data, "delLoading", false);
        })
        .catch((err) => {
          this.$set(data, "delLoading", false);
        });
    },
    // 国家奖学金
    delNation(data) {
      this.$set(data, "delLoading", true);
      nation
        .del([data.sqid])
        .then((res) => {
          this.$notify({
            type: "success",
            message: "操作成功",
            duration: 2000,
          });
          this.init();
          this.$set(data, "delLoading", false);
        })
        .catch((err) => {
          this.$set(data, "delLoading", false);
        });
    },
    //特困生
    delTks(data) {
      this.$set(data, "delLoading", true);
      tks
        .del([data.sqid])
        .then((res) => {
          this.$notify({
            type: "success",
            message: "操作成功",
            duration: 2000,
          });
          this.init();
          this.$set(data, "delLoading", false);
        })
        .catch((err) => {
          this.$set(data, "delLoading", false);
        });
    },
    //国家助学金
    delNationZxj(data) {
      this.$set(data, "delLoading", true);
      gjzxj
        .del([data.sqid])
        .then((res) => {
          this.$notify({
            type: "success",
            message: "操作成功",
            duration: 2000,
          });
          this.init();
          this.$set(data, "delLoading", false);
        })
        .catch((err) => {
          this.$set(data, "delLoading", false);
        });
    },
    //自定义奖助
    delCustom(data) {
      this.$set(data, "delLoading", true);
      custom.del([data.sqid]).then((res) => {
        this.$notify({
          type: "success",
          message: "操作成功",
          duration: 2000,
        });
        this.init();
        this.$set(data, "delLoading", false);
      });
    },
    onConfirm(value) {
      this.showYearPicker = false;
      this.query.year = value.label;
      this.toQuery();
    },
    cleanSearch() {
      this.searchTetx = "";
      this.query = {};
      this.toQuery();
    },
    convertData(dict) {
      let options = [];
      dict.forEach((item) => {
        let data = { text: item.label + "年", value: item.value };
        options.push(data);
      });
      if (options) {
        this.query.year = new Date().getFullYear().toString();
      }
      return options;
    },
    changeYear(val) {
      this.$nextTick((e) => {
        this.query.year = val;
      });
      this.toQuery();
    },
    openShowHandler() {
      // 默认选中数据
      this.dict.xj_nj.forEach((item, index) => {
        if (this.query.year) {
          if (this.query.year === item.value) {
            this.defaultIndex = index;
          }
        } else if (new Date().getFullYear().toString() === item.value) {
          this.defaultIndex = index;
        }
      });
      this.showYearPicker = true;
    },
    stuDisableBtn(spzt, type) {
      let status = parseInt(spzt);
      if ([9].indexOf(status) > -1) {
        return true;
      }
      if ([101, 201, 301, 2, 3].indexOf(status) > -1) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@/assets/styles/student/jzapply.scss";
::v-deep .van-dropdown-menu__bar {
  height: 44px;
  box-shadow: 0 0px 0px rgb(100 101 102 / 12%);
}
.detail-pos {
  position: absolute;
  top: 50%;
  right: 15px;
}
#opt-style {
  .van-cell__title {
    min-width: 50%;
    font-size: 14px;
    line-height: 32px;
  }
}
</style>
