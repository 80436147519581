import request from '@/utils/request'
import qs from 'qs'

export function add(data) {
  return request({
    url: 'api/award/project',
    method: 'post',
    data
  })
}


export function del(ids) {
  return request({
    url: 'api/award/project/',
    method: 'delete',
    data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/award/project',
    method: 'put',
    data
  })
}

export function download(params) {
  return request({
    url: 'api/award/project/download' + '?' + qs.stringify(params, { indices: false }),
    method: 'get',
    responseType: 'blob'
  })
}

export function getForm(fomrId){
  return request({
    url: 'api/award/project/form/'+fomrId,
    method: 'get'
  })
}

export function getAwardById(fomrId){
  return request({
    url: 'api/award/project/getAwardById/'+fomrId,
    method: 'get'
  })
}

export function getAwardByIds(params){
  return request({
    url: 'api/award/project/getAwardByIds',
    method: 'get',
    params
  })
}


/**
 * 提交表单
 */
export function submit(data){
  return request({
    url: 'api/award/project/submit',
    method: 'post',
    data
  })
}

export function deleteDj(ids) {
  return request({
    url: 'api/award/project/deleteDj/',
    method: 'delete',
    data: ids
  })
}

export default { del, edit, add, download }
