<template>
  <div id="StuProject">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.schshi" show-action placeholder="请输入项目名称或代码">
        <template #action>
          <div @click="toQuery">查询
          </div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text">
              {{row.schshiName}}
            </span>
          </template>
          <template #label>
            <div class="custom-centent  hidden-text" style="width:90%">
              <van-icon name="clock-o" size="14" /> 开始时间: <span style="letter-spacing: 0px;">{{row.startTime }}</span>
            </div>
            <div class="custom-centent margin6 hidden-text" style="width:90%">
              <van-icon name="underway-o" size="14" /> 结束时间: <span style="letter-spacing: 0px;">{{ row.endTime}}</span>
            </div>
            <div class="custom-centent margin6 hidden-text">
              <van-icon name="apps-o" size="14" /> 项目类型: <span>{{row.typeNo ==='jxj'? "奖学金":'助学金'}}</span>
            </div>
          </template>
          <template #right-icon>
            <div class="button-apply-div">
              <van-button class="table-edit-btn apply-btn" @click="toApply(row)" :disabled="isDisable(row)" plain round size="small" type="primary">申请</van-button>
            </div>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <div class="custom-centent  hidden-text">
              <span style="color:rgba(102, 102, 102, 1)"> 项目状态: </span><span :style="row.status === '1' ?'color:rgba(35, 171, 114, 1)':'color:rgba(232, 97, 42, 1)'">{{row.status === '1' ?"已开启":"未开启"}}</span>
            </div>
          </template>
          <template #right-icon>
            <span @click="toDetail(row)" style="color:#B2B2B2"> 详情
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { isDuringDate, getTermYear, getTerm } from "@/utils/index";
import { getList } from "@/api/modules/award/stujxj";
import { mapGetters } from "vuex";
import { webService } from "@/config/settings";

export default {
  name: "StuProject",
  dicts: ["xj_xqgl"],
  mixins: [initData],
  data() {
    return {
      url: "",
      value: "",
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {},
  methods: {
    isDuringDate,
    beforeInit() {
      this.url = "/api/award/project";
      this.sort = ["createTime,asc", "id,desc"];
      return true;
    },
    isDisable(data) {
      if (data.status !== "1") {
        return true;
      }
      return !isDuringDate(data.startTime, data.endTime);
    },
    toApply(data) {
      let _this = this;
      let termYear = getTermYear(
        _this.dict.xj_xqgl,
        (new Date().getMonth() + 1).toString()
      );
      let term = getTerm(
        _this.dict.xj_xqgl,
        (new Date().getMonth() + 1).toString()
      );
      let xqsqcs = "";
      data.jxjdjglList.forEach((item) => {
        if (item.gradeCode === term.xqCode) {
          xqsqcs = item.pbv;
        }
      });
      getList({
        schshiId: data.id,
        termYear: termYear,
        typeNo: data.typeNo,
      }).then((res) => {
        if (data.isFreq == true && parseInt(data.freq) <= res.length) {
          //每年
          _this.$notify({
            type: "warning",
            message:
              "已达到申请上限，要求每年" +
              data.freq +
              "次，当前已申请" +
              res.length +
              "次，不可再申请！",
            duration: 1500,
          });
          return;
        }
        let dqsq = res.filter((item) => {
          return item.teremCode === term.xqCode;
        });
        if (data.isFreq == false && parseInt(xqsqcs) <= dqsq.length) {
          //每学期
          _this.$notify({
            type: "warning",
            message:
              "已达到申请上限，要求每学期" +
              xqsqcs +
              "次，当前已申请" +
              dqsq.length +
              "次，不可再申请！",
            duration: 1500,
          });
          return;
        }
        if (data.isCustom) {
          let param =
            "id=" +
            data.id +
            "&title=" +
            data.schshiName +
            "&stu=yes&meta=奖助申请" +
            "&schshiNo=" +
            data.schshiNo +
            "&schshiName=" +
            data.schshiName +
            "&typeNo=" +
            data.typeNo +
            "&apkToken=" +
            this.token;
          window.location.href = webService + "/#/app/custom/form?" + param;
        } else {
          _this.$router.push({
            path: "/xs/a05/" + data.schshiNo + "/form",
          });
        }
      });
    },
    toDetail(data) {
      this.$router.push({
        path: "/xs/a05/project/detail",
        query: data,
      });
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@/assets/styles/student/jzapply.scss";
</style>
